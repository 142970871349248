<template lang="pug">
.loading-page
  h1.title Connecting to Sharesight...
  b-progress(type="is-primary" v-if="loading")
  b-message(type="is-danger" title="Error" v-if="error" :closable="false") {{error}}
  b-message(type="is-info" title="Success" v-if="success" :closable="false")
    | Successfully connected to your Sharesight account.
</template>

<script>
import firebase from 'firebase/app'
import { mapState } from 'vuex'

const sharesightDoneCallback = firebase
  .functions()
  .httpsCallable('sharesightAuthDone')

export default {
  name: 'SharesightAuth',
  data() {
    return {
      loading: true,
      error: null,
      success: false,
      isConnecting: false
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', ['isPrerendering'])
  },
  watch: {
    user: {
      immediate: true,
      handler(user) {
        if (user === undefined || this.isPrerendering) {
          return
        }
        if (user === null) {
          this.loading = false
          this.error =
            'You have been logged out. Please close this window, log in again and then retry.'
          return
        }
        this.connect()
      }
    }
  },
  methods: {
    connect() {
      if (this.isConnecting) {
        return
      }
      this.isConnecting = true
      const { code, state } = this.$route.query
      if (!code) {
        this.loading = false
        this.error =
          'No code supplied! Something has gone horribly wrong. Please try again.'
        return
      }
      sharesightDoneCallback({ code, state }).then(result => {
        const { status, message } = result.data
        this.loading = false
        this.success = status === 'ok'
        if (this.success) {
          window.sharesightAuthStatus = true
        } else {
          this.error = message
        }
      })
    }
  },
  metaInfo: {
    title: 'Connecting to Sharesight'
  }
}
</script>

<style lang="sass" scoped>
.loading-page
  position:        fixed
  top:             0
  left:            0
  right:           0
  bottom:          0
  z-index:         250
  background:      white
  display:         flex
  flex-direction: column
  align-items:     center
  justify-content: center
.progress-wrapper
  width: 250px
.title
  text-align: center
</style>
